<template>
    <div class="w-full lg:w-10/12 mx-auto flex flex-wrap text-center lg:text-left">
        <div class="w-full lg:w-6/12 mb-8">
            <label for="email" class="font-point-demo-bold text-blue text-xl uppercase">Email</label>
            <input v-model="email"
                   id="email"
                   type="email"
                   required
                   :disabled="loading"
                   class="mt-2 mb-8 appearance-none border border-blue3 focus:border-blue rounded-large w-full py-3 px-5 font-point-demo-light text-lg text-magenta focus:outline-none">

            <label for="name" class="font-point-demo-bold text-blue text-xl uppercase">Name on card</label>
            <input v-model="name"
                   id="name"
                   type="text"
                   required
                   :disabled="loading"
                   class="mt-2 mb-8 appearance-none border border-blue3 focus:border-blue rounded-large w-full py-3 px-5 font-point-demo-light text-lg text-magenta focus:outline-none">

            <label class="font-point-demo-bold text-blue text-xl uppercase">Card information</label>
            <div class="mb-8">
                <div ref="elements"></div>
            </div>

            <div v-if="isCustomPrice">
                <label for="custom_price" class="font-point-demo-bold text-blue text-xl uppercase">
                    Pay What You Want (min {{ minCustomPrice }})
                </label>
                <input v-model="customPrice"
                       id="custom_price"
                       name="custom_price"
                       type="text"
                       required
                       :disabled="loading"
                       class="mt-2 mb-8 appearance-none border border-blue3 focus:border-blue rounded-large w-full py-3 px-5 font-point-demo-light text-lg text-magenta focus:outline-none">
            </div>

            <div v-if="errors">
                <div v-for="error in errors" :key="error.message" class="w-full mt-4 px-32 py-4 border border-magenta bg-pink3 rounded-full text-lg text-magenta font-point-demo-bold">{{ error.message }}</div>
            </div>

            <img v-if="loading" src="/assets/front/img/loader2.svg" width="60px" height="60px" class="mx-auto"/>

            <button v-else @click="subscribe"
                    type="button"
                    class="w-full mt-8 px-32 py-4 bg-magenta rounded-full text-center text-lg text-white font-point-demo-bold capitalize">
                Subscribe
            </button>
        </div>
        <div class="w-full lg:w-3/12 lg:ml-32">
            <img src="/assets/front/img/dancing-with-mask-2.svg" class="max-w-md mx-auto" alt="">
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    props: {
        stripePublicKey: {
            type: String,
            required: true
        },
        intentClientSecret: {
            type: String,
            required: true
        },
        submitUrl: {
            type: String,
            required: true
        },
        city: {
            type: String,
            required: true
        },
        existingEmail: String,
        existingName: String,
        isCustomPrice: {
            type: Boolean,
            required: true
        },
        minCustomPrice: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            loading: false,
            stripe: undefined,
            cardElement: undefined,
            name: this.existingName,
            email: this.existingEmail,
            customPrice: null,
            errors: [],
            setupIntent: null
        }
    },
    mounted() {
        this.$el.removeAttribute('style')

        this.stripe = Stripe(this.stripePublicKey)
        const elements = this.stripe.elements()
        this.cardElement = elements.create('card', {
            style: {
                base: {
                    color: '#fd3670',
                    fontWeight: 400,
                    fontFamily: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif',
                    fontSize: '18px',
                    fontSmoothing: 'antialiased',
                    ':-webkit-autofill': {
                        color: '#fd3670',
                        backgroundColor: '#fff',
                    },
                    '::placeholder': {
                        color: '#9d98ee',
                    },
                },
                invalid: {
                    iconColor: '#ca2a5a',
                    color: '#ca2a5a',
                },
            },
        })
        this.cardElement.mount(this.$refs.elements)
    },
    methods: {
        async subscribe() {
            this.loading = true
            this.errors = []

            if (!this.setupIntent) {
                const {setupIntent, error} = await this.stripe.confirmCardSetup(
                    this.intentClientSecret, {
                        payment_method: {
                            card: this.cardElement,
                            billing_details: {
                                name: this.name
                            }
                        }
                    }
                )

                if (error) {
                    this.loading = false
                    return this.errors.push(error)
                }

                this.setupIntent = setupIntent
            }

            axios.post(this.submitUrl, {
                email: this.email,
                city: this.city,
                is_custom_price: this.isCustomPrice,
                custom_price: this.customPrice,
                payment_method: this.setupIntent.payment_method,
            })
            .then((response) => {
                this.errors = []
                window.location.href = response.data.redirect_url
            }, error => {
                if (error.response.status === 422) {
                    this.errors = Object.values(error.response.data.errors).flat().map(message => ({message}))
                } else {
                    this.errors = ['An unknown error occurred']
                }
                this.loading = false
            })
        }
    }
}
</script>
