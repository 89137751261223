<template>
    <div v-if="visible"
         :class="{visible: 'opened-modal modal-active'}"
         class="modal fixed w-screen h-screen top-0 left-0 flex items-center justify-center z-50">
        <div class="modal-overlay absolute w-full h-full" @click="hideModal()"></div>

        <div class="modal-container relative bg-white w-11/12 max-h-full md:max-w-xl mx-auto rounded-large shadow-xl z-50 overflow-y-auto">
            <div class="modal-content p-5 xsm:p-6 sm:p-10">
                <div class="flex justify-between items-center mb-4">
                    <div class="text-xl sm:text-2xl lg:text-3xl font-point-demo-bold text-blue uppercase">
                        {{ data.title || "Let's Discology" }}
                    </div>
                    <div @click="hideModal()" class="modal-close cursor-pointer z-50 rounded-full p-3">
                        <svg class="fill-current text-lightBlue" xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                             viewBox="0 0 14 14">
                            <path d="M2.584.484l4.408 4.348L11.311.438c.59-.6 1.528-.581 2.096.042a1.63 1.63 0 0 1-.04 2.215L9.164 6.974l4.36 4.3c.603.596.622 1.58.04 2.2a1.493 1.493 0 0 1-2.147.041L7.007 9.167 2.69 13.56c-.59.6-1.529.581-2.097-.042a1.63 1.63 0 0 1 .04-2.215l4.205-4.279-4.36-4.3a1.582 1.582 0 0 1-.04-2.2A1.493 1.493 0 0 1 2.584.485z"/>
                        </svg>
                    </div>
                </div>
                <component
                    :is="component"
                    :data="data"
                    :trial-days="trialDays"
                    :trial-ends-at="trialEndsAt || ''"/>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
    props: {
        trialDays: Number,
        trialEndsAt: String,
    },
    computed: {
        ...mapState({
            visible: `modalVisible`,
            component: `modalComponent`,
            data: `modalData`,
        }),
    },
    watch: {
        visible() {
            document.body.classList.toggle('modal-active')
        },
    },
    created() {
        this.handleEscapeKey()
    },
    methods: {
        ...mapMutations([`hideModal`]),

        handleEscapeKey() {
            const escapeHandler = (evt) => {
                evt = evt || window.event
                let isEscape
                if ('key' in evt) {
                    isEscape = (evt.key === 'Escape' || evt.key === 'Esc')
                } else {
                    isEscape = (evt.keyCode === 27)
                }
                if (isEscape && this.visible) {
                    this.hideModal()
                }
            }

            document.addEventListener(`keydown`, escapeHandler)

            this.$once(`hook:destroyed`, () => {
                document.removeEventListener(`keydown`, escapeHandler)
            })
        }
    },
};
</script>
